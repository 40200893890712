import React from "react"

import { Card, Layout, Button, Typography, Row, Col } from "antd"
// import Slider from "react-slick"

import { OutboundLink } from "gatsby-plugin-google-gtag"

import SEO from "../components/seo"
import Logo from "../components/logo"
import Colors from "../utils/colors"

const { Header, Content } = Layout

const { Title } = Typography

const ThanksPage = props => {
  // const settings = {
  //   className: "center",
  //   centerMode: true,
  //   infinite: true,
  //   centerPadding: "30px",
  //   slidesToScroll: 4,
  //   slidesToShow: 3,
  //   speed: 500,
  // }

  return (
    <div className="App" style={styles.welcomeContainer}>
      <Layout style={styles.layout}>
        <SEO title="Elenas Landing" />
        <Header style={styles.header}>
          <Logo />
        </Header>
        <Content style={styles.container}>
          <section
            style={{
              ...styles.section,
              ...styles.bgSuccess,
              ...styles.textCenter,
              display: "flex",
            }}
            className="success"
          >
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ flexDirection: "column", flexFlow: "row wrap", flex: 1 }}
            >
              <Col sm={24} md={14} lg={16}>
                <div style={styles.sectionTextWrap}>
                  <img
                    alt=""
                    style={styles.bannerImage}
                    src={require("../images/vp-2.svg")}
                  />
                  <Title
                    style={{
                      ...styles.sectionTitle,
                      ...styles.textWhite,
                      fontSize: 48,
                    }}
                  >
                    <span aria-label="party-popper" role="img">
                      🎉
                    </span>{" "}
                    ¡Bienvenida a Elenas!{" "}
                    <span aria-label="party-popper" role="img">
                      🎉
                    </span>
                  </Title>
                  <p
                    style={{
                      ...styles.sectionText,
                      ...styles.textWhite,
                    }}
                  >
                    Gracias por hacer parte de nuestra comunidad de mujeres
                    poderosas. <strong>Recibir tu código de embajadora</strong>{" "}
                    y comenzar a ganar comisiones por cada venta es mú fácil.{" "}
                    <strong>Solo sigue los pasos a continuación.</strong>
                  </p>
                </div>
              </Col>
            </Row>
          </section>

          <section
            className="products"
            style={{
              ...styles.section,
              ...styles.bgWhite,
            }}
          >
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{
                flexDirection: "column",
                flexFlow: "row wrap",
              }}
            >
              <Col sm={24} md={12}>
                <Title style={{ ...styles.sectionTitle }}>
                  ¿Estás lista para vender con Elenas?{" "}
                </Title>
                <ol
                  style={{
                    ...styles.sectionText,
                    fontSize: 18,
                    marginBottom: 48,
                  }}
                >
                  <li>
                    Comparte el producto a continuación con al menos tres de tus
                    contactos.
                    <strong>
                      <em>
                        {" "}
                        Parte de ser buena embajadora es saber que productos le
                        pueden interesar a cada cliente.
                      </em>
                    </strong>{" "}
                    <span aria-label="smiley" role="img">
                      🙂
                    </span>
                  </li>
                  <li>
                    Envía a nuestro{" "}
                    <OutboundLink
                      href="https://wa.me/573132122098?text=Estas%20son%20las%20capturas%20de%20pantalla"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        window.analytics.track("Share screenshots")
                        typeof window !== "undefined" &&
                          window.gtag("event", "Share screenshots")
                        window.fbq("track", "Share screenshots", {
                          content_name: "Thanks",
                        })
                      }}
                    >
                      Whatsapp (+57 310 667 8989)
                    </OutboundLink>{" "}
                    tres capturas de pantalla distintas de los mensajes que has
                    compartido.
                    <strong>
                      <em>
                        {" "}
                        Puedes personalizar el mensaje sugerido para aumentar la
                        probabilidad de venta.
                      </em>
                    </strong>{" "}
                    <span aria-label="smiley" role="img">
                      😉
                    </span>
                  </li>
                  <li>
                    Recibirás el enlace de decarga de tu app de embajadora, en
                    donde puedes acceder a tu código único con el cual tus
                    clientes pueden comprar en{" "}
                    <OutboundLink
                      href="http://bit.ly/elenas-site"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      elenas.la
                    </OutboundLink>{" "}
                    y tu obtienes tu comisión.{" "}
                    <strong>
                      <em>
                        Además, puedes hacer segumiento a tus ordenes y estar al
                        tanto de lanzamientos y contenido para mejorar tus
                        ventas.
                      </em>
                    </strong>{" "}
                    <span aria-label="smiley" role="img">
                      😄
                    </span>
                  </li>
                </ol>
              </Col>
              <Col sm={24} md={12}>
                <Card
                  hoverable
                  bordered={false}
                  style={{
                    width: 288,
                    margin: "0 auto",
                    textAlign: "left",
                    boxShadow: "0 1px 8px rgba(164, 146, 209, .2)",
                  }}
                  cover={
                    <img
                      alt="product-1"
                      src={require("../images/producto-1@2x.jpg")}
                    />
                  }
                >
                  <div>
                    <p style={styles.productBrand}>Dermanat</p>
                    <Title level={4} style={styles.productName}>
                      Exfoliante de Coco
                    </Title>
                    <p style={styles.productPrice}>$18.000</p>
                    <p style={styles.productDecription}>
                      Este producto es especial para la limpieza de la piel,
                      especialmente de esas areas oscuras. ¡Recomiéndalo a tus
                      amigas más cercanas!
                    </p>
                    <Button
                      style={styles.productShareButton}
                      block
                      size="large"
                      onClick={() => {
                        window.analytics.track("Share product")
                        typeof window !== "undefined" &&
                          window.gtag("event", "Share product")
                        window.fbq("track", "Share product", {
                          content_name: "Landing",
                        })
                      }}
                    >
                      <OutboundLink
                        href="https://wa.me/?text=%C2%A1Hola%21+Te+cuento+que+ahora+soy+parte+de+Elenas%2C+una+comunidad+de+mujeres+emprendedoras+que+venden+los+productos+de+las+mejores+marcas+colombianas.+Quiero+compartir+contigo+uno+de+los+productos+que+estoy+vendiendo%2C+el+exfoliante+de+coco+de+Bio+Essens.+%C2%A1Funciona+muy+bien%21+http%3A%2F%2Fbit.ly%2Felenas-coco"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-action="share/whatsapp/share"
                      >
                        <img
                          style={{
                            display: "inline-block",
                            maxHeight: 18,
                            margin: 8,
                          }}
                          alt=""
                          src={require("../images/share-square.svg")}
                        ></img>
                        Compartir
                      </OutboundLink>
                    </Button>
                  </div>
                </Card>
              </Col>
            </Row>
          </section>

          <footer className="footer">
            <Row
              type="flex"
              justify="center"
              style={{
                flexDirection: "row",
                flexFlow: "row wrap",
                justifyContent: "space-around",
              }}
            >
              <Col col={24} sm={8} md={12}>
                <img
                  alt="Logo Elenas"
                  style={{ maxHeight: 24 }}
                  src={require("../images/logo-p.svg")}
                ></img>
              </Col>
              <Col col={24} sm={16} md={12}>
                <p style={{ textAlign: "right", fontSize: 12, marginTop: 8 }}>
                  © 2019 Elenas | Todos los derechos reservados
                </p>
              </Col>
            </Row>
          </footer>
        </Content>
      </Layout>
    </div>
  )
}
export default ThanksPage

const styles = {
  welcomeContainer: {},
  container: {
    minHeight: "auto",
  },
  header: {
    position: "absolute",
    backgroundColor: "transparent",
    left: 0,
    right: 0,
    paddingTop: 16,
  },
  layout: {
    flex: 1,
    backgroundColor: "#FAFAFF",
  },
  banner: {},
  bannerTextWrap: {},
  bannerTitle: {
    color: "#fff",
  },
  bannerText: {
    color: "#fff",
    fontSize: 20,
  },
  bannerImage: {
    maxWidth: "100%",
    height: "auto",
  },
  bannerButton: {
    fontSize: 24,
    fontWeight: "bold",
    height: 48,
    marginBottom: 24,
  },
  bgWhite: {
    backgroundColor: "#fff",
  },
  bgSuccess: {
    backgroundColor: Colors.brandSuccess,
  },
  bgGradient: {
    backgroundColor: Colors.brandPrimary,
    background: `linear-gradient(45deg, #00FFAE 0%, ${Colors.brandSuccess} 100%)`,
  },
  textCenter: {
    textAlign: "center",
  },
  textWhite: {
    color: "#fff",
  },
  valuePropImg: {
    marginBottom: 48,
    width: "100%",
    maxHeight: 144,
  },
  valuePropTitle: {
    marginBottom: 24,
  },
  valuePropButton: {
    fontSize: 20,
    fontWeight: "bold",
    height: 48,
    marginBottom: 50,
    paddingLeft: 40,
    paddingRight: 40,
  },
  pressImg: {
    marginBottom: 24,
    width: "100%",
    maxHeight: 48,
  },
  pressText: {
    fontSize: 14,
    fontStyle: "italic",
    color: "#A492D1",
    marginBottom: 32,
  },
  brandLogo: {
    maxHeight: 120,
  },
  brandName: {
    fontSize: 14,
  },
  requirementImg: {
    marginBottom: 24,
    maxHeight: 16,
  },
  requirementText: {
    fontSize: 14,
    fontStyle: "italic",
    color: "#A492D1",
  },
  section: {
    padding: 50,
    paddingBottom: 88,
    paddingTop: 80,
  },
  sectionTextWrap: {
    paddingTop: 50,
  },
  sectionTitle: {
    fontSize: 32,
    lineHeight: 1,
  },
  sectionText: {
    fontSize: 20,
  },
  sectionImage: {
    maxWidth: "100%",
    height: "auto",
  },
  sectionButton: {
    fontSize: 20,
    fontWeight: "bold",
    height: 48,
    marginBottom: 24,
    paddingLeft: 40,
    paddingRight: 40,
  },
  sectionInput: {
    marginBottom: 24,
  },
  productBrand: {
    fontSize: 10,
    textTransform: "uppercase",
    color: "#977DD1",
    margin: 0,
  },
  productName: {
    fontSize: 24,
    marginBottom: 8,
  },
  productPrice: {
    fontSize: 14,
    color: Colors.brandInfo,
  },
  productDecription: {
    fontSize: 14,
  },
  productShareButton: {
    textTransform: "uppercase",
    height: 48,
    lineHeight: "48px",
  },
}
